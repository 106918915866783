import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../helpers";
import BlogPostPreviewGrid from "../components/BlogPostPreviewGrid";
import { Container, SubMenu, SEO, GraphQLError } from "../components/elements";

import Layout from "../containers";

import { ResponsiveTitle1 } from "../components/styled";
import useFirebaseAnalytics from "../hooks/useFirebaseAnalytics";

export const query = graphql`
  query BooksPageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        isBackgroundPost: { ne: true }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          tags {
            tag {
              title
            }
          }
        }
      }
    }
    genres: allSanityPost {
      group(field: genres___title) {
        fieldValue
      }
    }
  }
`;

const ArchivePage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLError errors={errors} />
      </Layout>
    );
  }

  const postNodes =
    data &&
    data.posts &&
    mapEdgesToNodes(data.posts).filter((post) => post.tags.length === 0);
  const genres = data && data.genres ? data.genres.group : [];
  const title = "Books";
  useFirebaseAnalytics(`PAGE: ${title}`);
  return (
    <Layout>
      <SEO title={title} />
      <SubMenu items={genres} type="genres" />
      <Container>
        <ResponsiveTitle1>{title}</ResponsiveTitle1>
        {postNodes && postNodes.length > 0 && (
          <BlogPostPreviewGrid nodes={postNodes} />
        )}
      </Container>
    </Layout>
  );
};

export default ArchivePage;
